<template>
  <!-- pending sale dialog -->
  <v-dialog v-model="show" width="50%">
    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title class="white--text">Pending sale</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="3">
            <v-text-field
              :value="pendingSale.pending_sale_id"
              label="Pending sale ID"
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              :value="pendingSale.pipeline_name"
              label="Pipeline"
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              :value="pendingSale.data_type"
              label="Data type"
              readonly
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- sale key value data -->
        <v-row>
          <v-col cols="12">
            <v-toolbar elevation="0">
              <v-spacer></v-spacer>

              <v-text-field
                v-model="pendingSaleSearch"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-toolbar>

            <v-data-table
              :headers="pendingSaleHeaders"
              :search="pendingSaleSearch"
              :items="PendingSaleData"
              :items-per-page="100"
              :sort-by.sync="pendingSaleSortBy"
              class="elevation-0 mt-5"
              dense
              multi-sort
              hide-default-footer
            >
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" text @click="closePendingSaleClick()"
          >OK</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    pendingSale: {
      type: Object,
    },
    value: {
      type: Boolean,
    },
  },

  data() {
    return {
      pendingSaleHeaders: [
        { text: "Data type", value: "key_type" },
        { text: "Column name", value: "key" },
        { text: "Value", value: "value" },
      ],

      pendingSaleSearch: "",
      pendingSaleSortBy: ["key_type", "key"],
    };
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    PendingSaleData() {
      let result = [];
      let key_type = "";
      if (this.pendingSale) {
        for (var item in this.pendingSale) {
          if (
            item.startsWith("_") ||
            item === "created" ||
            item.startsWith("import_batch_") ||
            item.startsWith("pipeline")
          ) {
            key_type = "Sprout internal";
          } else if (
            item.startsWith("address") ||
            item.startsWith("customer") ||
            item.startsWith("house") ||
            item === "city" ||
            item === "state" ||
            item === "zip"
          ) {
            key_type = "Customer";
          } else {
            key_type = "Sales";
          }

          result.push({
            key: item,
            value: this.pendingSale[item],
            key_type: key_type,
          });
        }

        result = result.sort((a, b) => {
          return a.key - b.key;
        });
      }

      return result;
    },
  },
};
</script>

<style></style>
